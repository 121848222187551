import PropTypes from 'prop-types';
import { imageSizes, buildImageUrl } from '../utils/images';

const ImageUpload = ({ record: { image, name }, size, linkToFull, ...imageProps }) => {
  if (!image) {
    return null;
  }

  const img = (
    <img
      src={buildImageUrl(image, size)}
      alt={name}
      style={imageSizes[size]}
      {...imageProps}
    />
  );

  if (linkToFull) {
    return (
      <a
        href={buildImageUrl(image, 'full')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {img}
      </a>
    );
  }

  return img;
};

ImageUpload.propTypes = {
  record: PropTypes.object,
  size: PropTypes.string,
  linkToFull: PropTypes.bool
};

ImageUpload.defaultProps = {
  linkToFull: false
};

export default ImageUpload;
