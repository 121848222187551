import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  radio: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  formControlLabelRoot: {
    marginTop: theme.spacing(1),
  },
  formControlLabelLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  radioGroup: {
    '& > *:first-child': {
      marginTop: 0,
    },
  },
}));

const RadioGroupControl = ({
  name,
  options,
  disabled,
  form,
  label,
  helperText: origHelperText,
  value,
  touched,
  className,
  ...controlProps
}) => {
  const classes = useStyles();
  const helperText = ((touched || form.touched[name]) && form.errors[name]) || origHelperText;
  return (
    <FormControl
      component="fieldset"
      error={(touched || form.touched[name]) && Boolean(form.errors[name])}
      className={clsx(classes.formControl, className)}
    >
      {label && <FormLabel component="legend" className={classes.label}>{label}</FormLabel>}
      <RadioGroup
        id={name}
        value={value === true || value === false ? value.toString() : value}
        {...controlProps}
        onChange={(e, newValue) => {
          e.preventDefault();
          let translatedValue = newValue;
          if (newValue === 'true') {
            translatedValue = true;
          } else if (newValue === 'false') {
            translatedValue = false;
          }
          form.setFieldValue(name, translatedValue);
        }}
        className={classes.radioGroup}
      >
        {map(options, (option) => {
          return (
            <FormControlLabel
              name={name}
              key={option.value}
              value={
                option.value === true || option.value === false ?
                  option.value.toString() :
                  option.value
              }
              checked={option.value === value}
              control={
                <Radio
                  classes={{ root: classes.radio }}
                />
              }
              label={option.label}
              disabled={disabled || option.disabled}
              classes={{
                root: classes.formControlLabelRoot,
                label: classes.formControlLabelLabel,
              }}
            />
          );
        })}
      </RadioGroup>
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

RadioGroupControl.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  form: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  touched: PropTypes.bool,
  className: PropTypes.string
};

export default RadioGroupControl;
