import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import Button from '@material-ui/core/Button';
import ConfirmButton from '../confirm-button';

const CopyEventButton = (props) => {
  const { event, buttonComponent: ButtonComponent, children, ...rest } = props;

  const handleCopy = useCallback((e) => {
    e.preventDefault();
    Router.push(
      `/events/new?copyEventId=${event.id}`
    );
  }, [event]);

  if (event.series) {
    return (
      <ConfirmButton
        onClick={handleCopy}
        label={children}
        confirmMessage={`This event is part of a series. If you're adding another event to the series, click "Manage Series" instead to add a new event as part of the series. Events cannot be added retroactively to a series. Are you sure you wish to copy this event instead of adding to the series?`}
        buttonComponent={ButtonComponent}
        {...rest}
      />
    );
  }

  return (
    <ButtonComponent
      onClick={handleCopy}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};

CopyEventButton.propTypes = {
  event: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  buttonComponent: PropTypes.func,
};

CopyEventButton.defaultProps = {
  buttonComponent: Button,
};

export default CopyEventButton;
