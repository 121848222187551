import { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmButton = (props) => {
  const {
    buttonComponent: ButtonComponent,
    confirmTitle,
    confirmMessage,
    children,
    onClick,
    label,
    ...buttonProps
  } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCancel = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
  }, []);

  const handleConfirm = useCallback((e) => {
    setOpen(false);
    onClick(e);
  }, [onClick]);

  return (
    <Fragment>
      <ButtonComponent onClick={handleClickOpen} {...buttonProps}>
        {label}
      </ButtonComponent>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        <DialogContent>
          {children ? (
            <div id="alert-dialog-description">
              {children}
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {confirmMessage}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

ConfirmButton.propTypes = {
  label: PropTypes.node,
  confirmTitle: PropTypes.node,
  confirmMessage: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  buttonComponent: PropTypes.any,
};

ConfirmButton.defaultProps = {
  confirmTitle: 'Please Confirm',
  confirmMessage: 'Are you sure?',
  buttonComponent: Button,
};

export default ConfirmButton;
