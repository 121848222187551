import map from 'lodash/map';
import get from 'lodash/get';

const eventCategories = {
  TRAINING: {
    name: 'Training',
  },
  RACE: {
    name: 'Race',
  },
  CAMPING: {
    name: 'Camping',
  },
  MULTI_DAY: {
    name: 'Multi-Day',
  },
  HIKE: {
    name: 'Hike-A-Bike',
  },
  CASUAL: {
    name: 'Casual',
  },
  ROAD: {
    name: 'Road',
  },
  MOUNTAIN: {
    name: 'Mountain',
  },
  CYCLOCROSS: {
    name: 'Cyclocross',
  },
  GRAVEL: {
    name: 'Gravel',
  },
  PRESENTATION: {
    name: 'Presentation',
  },
  COFFEE_OUTSIDE: {
    name: 'Coffee Outside',
  },
  MARKET: {
    name: 'Market',
  },
  MEETUP: {
    name: 'Meetup',
  },
  DEMOS: {
    name: 'Demos',
  },
  PAID: {
    name: 'Paid',
  },
  MIXED_TERRAIN: {
    name: 'Mixed-terrain',
  },
  NOVICE: { // deprecated
    name: 'Novice',
  },
};

const eventCategoryIdsByType = {
  RIDE: [
    'CASUAL',
    'TRAINING',
    'RACE',
    'ROAD',
    'GRAVEL',
    'MOUNTAIN',
    'CYCLOCROSS',
    'MIXED_TERRAIN',
    'HIKE',
    'MULTI_DAY',
    'CAMPING',
    'PAID',
  ],
  EVENT: [
    'PRESENTATION',
    'MEETUP',
    'COFFEE_OUTSIDE',
    'MARKET',
    'PAID',
  ],
  ONLINE_EVENT: [
    'PRESENTATION',
    'MEETUP',
    'COFFEE_OUTSIDE',
    'MARKET',
    'PAID',
  ],
};

export const getEventCategoryIds = () => Object.keys(eventCategories);

export const getEventCategoryIdsForType = (eventType) => eventCategoryIdsByType[eventType];

export const getEventCategoryName = (categoryId) => get(eventCategories[categoryId], 'name');

// export const getEventCategoryOptions = () => {
//   return map(eventCategories, ({ name }, key) => ({
//     label: name,
//     value: key
//   }));
// };

export const getEventCategoryOptionsForType = (eventType) => {
  return map(getEventCategoryIdsForType(eventType), (categoryId) => {
    return {
      label: getEventCategoryName(categoryId),
      value: categoryId
    };
  });
};
