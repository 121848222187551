import { useMemo } from 'react';
import PropTypes from 'prop-types';
import _some from 'lodash/some';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Favorite from '@material-ui/icons/Favorite';
import clsx from 'clsx';
import MaterialLink from './material-link';
import { useAppContext } from '../contexts/app';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  userLink: {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  avatar: {
    height: '1em',
    width: '1em',
    marginRight: theme.spacing(0.5),
  },
  iconWrapper: {
    marginLeft: theme.spacing(0.5),
    display: 'inline-flex',
    alignItems: 'center',
  },
  followingIcon: {
    color: theme.palette.secondary.dark,
    fontSize: 20,
  },
}));

const HostUser = ({ user, className, simple, ...rest }) => {
  const classes = useStyles();
  const { loggedInUser } = useAppContext();

  const following = useMemo(() => {
    if (simple || !loggedInUser) {
      return false;
    }
    return _some(loggedInUser.userFollows, ({ followingUser: { id: followingUserId } }) => (
      followingUserId === user.id
    ));
  }, [simple, loggedInUser, user]);

  return (
    <div className={clsx(classes.link, className)}>
      <MaterialLink
        href="/people/[username]"
        as={`/people/${user.username}`}
        className={classes.userLink}
        {...rest}
      >
        {user.avatar && (
          <Avatar
            alt={user.displayName}
            src={user.avatar.small.url}
            className={classes.avatar}
          />
        )}
        {user.displayName}
      </MaterialLink>
      {following && (
        <span
          className={classes.iconWrapper}
          title="You're following this person"
        >
          <Favorite className={classes.followingIcon} />
        </span>
      )}
    </div>
  );
};

HostUser.propTypes = {
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
  simple: PropTypes.bool,
};

HostUser.defaultProps = {
  simple: false,
};

export default HostUser;
