import { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _find from 'lodash/find';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Group from '@material-ui/icons/Group';
import Favorite from '@material-ui/icons/Favorite';
import MaterialLink from './material-link';
import { buildImageUrl } from '../utils/images';
import { useAppContext } from '../contexts/app';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  groupLink: {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  avatar: {
    height: '1em',
    width: '1em',
    marginRight: theme.spacing(0.5),
  },
  iconWrapper: {
    marginLeft: theme.spacing(0.5),
    display: 'inline-flex',
    alignItems: 'center',
  },
  verifiedIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  joinedIcon: {
    color: theme.palette.secondary.dark,
    fontSize: 20,
  },
  avatarMissingIcon: {
    color: '#333',
    marginRight: theme.spacing(0.5),
  },
}));

const HostGroup = ({ group, className, simple, skipVerification, ...rest }) => {
  const classes = useStyles();
  const { loggedInUser } = useAppContext();

  const membership = useMemo(() => {
    if (simple || !loggedInUser) {
      return null;
    }
    return _find(loggedInUser.groupMembers, ({ group: { id: groupId } }) => (
      groupId === group.id
    ));
  }, [simple, loggedInUser, group]);

  const joined = membership && membership.role !== 'REQUESTED';

  return (
    <div className={clsx(classes.link, className)}>
      <MaterialLink
        href="/groups/[slug]"
        as={`/groups/${group.slug}`}
        className={classes.groupLink}
        {...rest}
      >
        {group.image ? (
          <Avatar
            alt={group.name}
            src={buildImageUrl(group.image, '20x20')}
            className={classes.avatar}
          />
        ) : (
          <Group
            className={classes.avatarMissingIcon}
            fontSize="small"
          />
        )}
        {group.name}
      </MaterialLink>
      {!skipVerification && group.verified && (
        <span
          title="This group has been verified by Everyday Rides"
          className={classes.iconWrapper}
        >
          <VerifiedUserIcon 
            className={classes.verifiedIcon}
          />
        </span>
      )}
      {joined && (
        <span
          className={classes.iconWrapper}
          title="You're a member of this group"
        >
          <Favorite className={classes.joinedIcon} />
        </span>
      )}
    </div>
  );
};

HostGroup.propTypes = {
  group: PropTypes.object.isRequired,
  className: PropTypes.string,
  simple: PropTypes.bool,
  skipVerification: PropTypes.bool,
};

HostGroup.defaultProps = {
  simple: false,
  skipVerification: false,
};

export default HostGroup;
