import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';
import MaterialTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  field: {
    margin: theme.spacing(1, 0),
  }
}));

const TextAreaField = (props) => {
  const {
    name,
    label,
    disabled,
    errorsDisabled,
    helperText,
    touched,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, form }) => (
        <MaterialTextField
          id={name}
          label={label}
          {...field}
          value={field.value || ''}
          error={
            !errorsDisabled &&
            (touched || Boolean(form.touched[field.name])) &&
            Boolean(form.errors[field.name])
          }
          helperText={
            (!errorsDisabled && (touched || Boolean(form.touched[field.name])) && form.errors[field.name]) ||
            helperText
          }
          fullWidth
          multiline
          minRows={2}
          maxRows={20}
          disabled={disabled}
          variant="outlined"
          className={clsx(classes.field, className)}
          {...rest}
        />
      )}
    </Field>
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  errorsDisabled: PropTypes.bool,
  helperText: PropTypes.node,
  className: PropTypes.string,
};

TextAreaField.defaultProps = {
  errorsDisabled: false,
};

export default TextAreaField;
