import { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import HostUser from './host-user';
import HostGroup from './host-group';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  in: {
    margin: theme.spacing(0, 0.5),
  },
}));

const Host = ({ event, className, simple, ...rest }) => {
  const classes = useStyles();
  const { hostType, group, user } = event;

  if (hostType === 'Group') {
    return (
      <HostGroup group={group} className={className} simple={simple} {...rest} />
    );
  } else if (hostType === 'User') {
    return (
      <div className={clsx(classes.link, className)}>
        <HostUser user={user} simple={simple} {...rest} />
        {group && (
          <Fragment>
            <span className={classes.in}> in </span>
            <HostGroup group={group} simple={simple} skipVerification {...rest} />
          </Fragment>
        )}
      </div>
    );
  }
};

Host.propTypes = {
  event: PropTypes.object,
  className: PropTypes.string,
  simple: PropTypes.bool,
};

export default Host;
